import { useTheme } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { primary_orange } from '../../../colors'

import ButtonElement from '../../../elements/buttons'
import TypographyElement from '../../../elements/typography'

const CtaWrapper = styled.div`
  padding: 15px 15px 15px 32px;
  border-radius: 150px;
  background-color: ${(props) => props.bg};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 117px;
  .textContainer {
    flex: 1;
  }
`

const SubsiteCta = ({ text, link }) => {
  const theme = useTheme()
  const color = theme?.globalTheme?.palette?.primaryCustom?.main

  return (
    <CtaWrapper bg={color || primary_orange}>
      <div className="textContainer">
        <TypographyElement variant="subsiteCta">{text}</TypographyElement>
      </div>
      <div className="buttonContainer">
        {(link?.href || link?.external_url) && (
          <ButtonElement
            variant="whiteLink"
            href={link.href || link.external_url}
          >
            {link.text}
          </ButtonElement>
        )}
      </div>
    </CtaWrapper>
  )
}

export default SubsiteCta
