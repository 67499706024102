import React from 'react'
import styled from 'styled-components'
import TypographyElement from '../../elements/typography'
import ImageBuilder from '../../elements/imageBuilder'

const HeaderLocation = styled.header`
  height: 352px;
  display: block;
  position: relative;
  overflow: hidden;
  background: #fff;
  background-size: cover;
  background-position: top center;

  > div.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover !important;
    }
  }

  > div.effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 0;
    text-align: center;

    // Effect
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    .clublogo {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 365px;

      .pagetitle {
        position: absolute;
        top: 50%;
        left: 18%;
        transform: translateY(-50%);
        z-index: 9;
      }

      div.whitebar {
        position: absolute;
        width: 120%;
        height: 400px;
        background: #fff;
        left: -2%;
        bottom: -128px;
        transform: rotate(-2.11deg);
      }
    }
    span img {
      object-fit: cover;
      object-position: center;
    }
  }

  // Mobile
  @media screen and (max-width: 900px) {
    .clublogo {
      .pagetitle {
        h1 {
          font-size: 60px !important;
        }
      }
    }
  }
  // Mobile
  @media screen and (max-width: 500px) {
    .clublogo {
      .pagetitle {
        left: 0 !important;
        h1 {
          font-size: 50px !important;
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
    .clublogo {
      .pagetitle {
        left: 0 !important;
        h1 {
          font-size: 40px !important;
        }
      }
    }
  }
`

const HeaderModule = ({ background, text }) => {
  return (
    <HeaderLocation>
      <div className="bg">
        <ImageBuilder decorativeOnly={true} image={background} layout="fill" />
      </div>
      <div className="effect">
        <div className="clublogo">
          <div className="pagetitle">
            <TypographyElement variant="headerTitleWhite">
              {text}
            </TypographyElement>
          </div>
          <div className="whitebar"></div>
        </div>
      </div>
    </HeaderLocation>
  )
}

export default HeaderModule
