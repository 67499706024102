import React from 'react'
import styled from 'styled-components'

const SkewContainer = styled.div`
  ${(props) =>
    props.displayBlock ? 'display: block;' : 'display: inline-block;'}
  ${(props) => (props.height ? `height: ${props.height}px;` : '')}
    position: relative;
  ${(props) => props.containerStyling}

  > div.skewWrapper {
    position: relative;
    padding-left: 30px;
    padding-right: 30px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      transform: skewX(${(props) => props.skewValue});
      /* z-index: -1; */
      right: 0;
      margin: 0 auto;
      ${(props) => props.extraStyling}
    }

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      right: 40px;
      left: 0;
      bottom: 0;
    }
  }

  ${(props) =>
    props.unskewMobile
      ? `
    @media screen and (max-width: 740px) {
      > div.skewWrapper {
        padding: 0;

        &:before, &:after {
          display: none;
        }        
      }
    }
  `
      : ''}

  @media screen and (max-width: 740px) {
    > div.skewWrapper {
      padding: 0;
    }
  }
  @media screen and (max-width: 740px) {
    > div.skewWrapper {
      padding: 0;
      width: 100%;
      .container {
        height: 100%;
        .textContainer {
          padding: 0;
        }
      }
      h3 {
        white-space: normal;
      }
    }
  }
`

const SkewWrapper = ({
  style,
  children,
  BeforeElement,
  AfterElement,
  displayBlock,
  containerStyling,
  unskewMobile = false,
  skew = '-12.35deg',
}) => {
  return (
    <SkewContainer
      displayBlock={displayBlock}
      skewValue={skew}
      extraStyling={style}
      containerStyling={containerStyling}
      unskewMobile={unskewMobile}
    >
      {BeforeElement && <BeforeElement extraStyle={style} />}
      <div className="skewWrapper">{children}</div>
      {AfterElement && <AfterElement extraStyle={style} />}
    </SkewContainer>
  )
}
export default SkewWrapper

const SkewImageWrapper = styled.div`
  position: relative;
  transform: skew(-${(props) => props.skew});
  overflow: hidden;
  ${(props) => (props.height ? `height: ${props.height}px` : ``)}
  ${(props) => props.containerStyling}

    > * {
    position: absolute;
    top: 0%;
    transform: skew(${(props) => props.skew});

    ${(props) =>
      props.unskewMobile
        ? `
      @media screen and (max-width: 640px) {
        transform: none;
      }
    `
        : ''}
  }

  span {
    max-width: 120% !important;
    width: 120% !important;
    transform: skew(${(props) => props.skew});

    @media screen and (max-width: 1500px) {
      max-width: 200% !important;
      width: 220% !important;
    }

    img {
      position: relative;
      top: calc(10% - ${(props) => props.height / 2}px) !important;
      ${(props) =>
        props.noTop
          ? `
                top: unset !important;
                height: 136px;
                width: 228px !important;
                max-width: unset !important;
            `
          : `
            `}
      ${(props) =>
        props.isCTA
          ? `
                top: unset !important;
                max-width: unset !important;
            `
          : ``}
    }
  }
`

export const SkewImage = ({
  children,
  skew,
  containerStyling,
  height,
  noTop = false,
  isCTA = false,
  unskewMobile = false,
}) => {
  return (
    <SkewImageWrapper
      height={height}
      containerStyling={containerStyling}
      skew={skew}
      noTop={noTop}
      isCTA={isCTA}
      unskewMobile={unskewMobile}
    >
      {children}
    </SkewImageWrapper>
  )
}
