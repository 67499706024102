'use client'
import React from 'react'
// import { createGlobalStyle } from 'styled-components'

import { POPUP_STORAGE_KEY } from './constants'
import { useFilterState } from './filterContext'
import Popup from './modules/popups'

// const PopUpBlocker = createGlobalStyle`
//     body, html {
//         width: 100%;
//         height: 100%;
//         overflow: hidden;
//     }
// `

const PopupWrapper = ({ children, popups, pageclass }) => {
  const [popup, setPopup] = React.useState()
  const [show, setShow] = React.useState(false)
  const { filterState } = useFilterState()
  const { category, filters } = filterState || {}

  const getRead = () => {
    let readItems = localStorage.getItem(POPUP_STORAGE_KEY)
    return readItems ? JSON.parse(readItems) : []
  }

  const setRead = (uuid) => {
    const items = getRead()
    const newItems = [...items, uuid]
    localStorage.setItem(POPUP_STORAGE_KEY, JSON.stringify(newItems))
    return newItems
  }

  const initPopups = ({ readItems }) => {
    // Filter for non read popups only
    let nonReadPopups = popups.filter((item) => {
      return !readItems.includes(item.uuid)
    })
    if (['locatieindex', 'clubindex'].includes(pageclass)) {
      // Check for active filters
      nonReadPopups = nonReadPopups.filter((item) => {
        const itemCategories = item?.criteria?.categories || []
        const itemSports = item?.criteria?.sports || []
        const categoryCheck =
          !itemCategories?.length || itemCategories.includes(category?.slug)
        const sportsCheck =
          !itemSports?.length ||
          filters.some((sport) => {
            return itemSports?.includes(sport?.slug)
          })
        return categoryCheck && sportsCheck
      })
    }
    setPopup(nonReadPopups?.length ? nonReadPopups[0] : {})
  }

  React.useEffect(() => {
    // Get array of read popup id's
    const readItems = getRead()
    initPopups({ readItems })
  }, [filters, category])

  React.useEffect(() => {
    // Set a timeout for the popup to show
    setShow(popup ? true : false)
  }, [popup])

  const handleClose = (uuid) => {
    const readItems = setRead(uuid)
    initPopups({ readItems })
  }

  return (
    <>
      {children}
      {show && popup ? (
        <Popup popup={popup} handleClose={() => handleClose(popup.uuid)} />
      ) : null}
      {show &&
        (popup?.type === 'fullscreen' ||
          popup?.type === 'fullscreenbottom') && (
          <style>
            {`
                        body, html {
                            width: 100% !important;
                            height: 100% !important;
                            overflow: hidden !important;
                        }
                    `}
          </style>
        )}
    </>
  )
}

export default PopupWrapper
