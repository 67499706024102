import React from 'react'
import styled from 'styled-components'
import ButtonElement from './buttons'

const FullScreenPopupWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  overflow-y: scroll;

  .popupContentContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .closeButton {
      cursor: pointer;
      right: 10px;
      position: absolute;
      z-index: 1;
      padding: 10px;
    }

    @media screen and (max-width: 890px) {
      top: 0;
      left: 0;
      transform: none;
      width: calc(100% - 40px);
      margin: 20px;
    }
  }
  ${(props) =>
    props.hide
      ? `
        z-index: -99999;
        height: 0;
        width: 0;
        opacity: 0;
    `
      : ``}
`

const BottomShadedPopupWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .popupContentContainer {
    position: absolute;
    left: 50%;
    bottom: 71px;
    transform: translateX(-50%);
    .closeButton {
      cursor: pointer;
      right: 10px;
      position: absolute;
      z-index: 1;
      padding: 10px;
    }
  }
`

const LowerRightCornerPopupWrapper = styled.div`
  z-index: 999999999;
  position: fixed;
  bottom: 45px;
  right: 51px;
  .popupContentContainer {
    position: absolute;
    right: 0;
    bottom: 0;
    .closeButton {
      cursor: pointer;
      right: 10px;
      position: absolute;
      z-index: 1;
      padding: 10px;
    }
  }
`

const BottomPopupWrapper = styled.div`
  z-index: 999999999;
  position: fixed;
  bottom: 42px;
  left: 50%;
  .popupContentContainer {
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    .closeButton {
      cursor: pointer;
      right: 10px;
      position: absolute;
      z-index: 1;
      padding: 10px;
    }
  }
`

const wrapperMap = {
  bottom: BottomPopupWrapper,
  fullscreen: FullScreenPopupWrapper,
  lowerright: LowerRightCornerPopupWrapper,
  bottomfullscreen: BottomShadedPopupWrapper,
}

const PopupModal = ({
  variant = 'fullscreen',
  handleClose,
  children,
  show = true,
}) => {
  const Wrapper = wrapperMap[variant]

  return (
    <Wrapper hide={!show}>
      <div className="popupContentContainer">
        <div className="closeButton">
          <ButtonElement variant="empty" handleClick={handleClose}>
            x
          </ButtonElement>
        </div>
        {children}
      </div>
    </Wrapper>
  )
}

export default PopupModal
