import React from 'react'

// Headers
import Homepage from './homepage'
import Sports from './sports'
import Club from './club'
import Location from './location'

import { useFilterState } from '../../filterContext'

// Table
const headerTable = {
  homepage: Homepage,
  sports: Sports,
  club: Club,
  location: Location,
}

// Module
const Header = ({
  variant = 'homepage',
  text,
  background,
  withFade,
  withEffect,
  headerImage,
  logo,
}) => {
  const { dynamicHeader } = useFilterState()?.filterState || {}
  if (variant == 'none') {
    return null
  }
  const pageImage = headerImage || background

  const Component = headerTable[variant]
  return (
    <Component
      withFade={withFade}
      text={text}
      background={pageImage || dynamicHeader?.url}
      withEffect={withEffect}
      img={logo}
    />
  )
}

export default Header
