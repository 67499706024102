import React from 'react'
import styled from 'styled-components'
import { fifth_black, link_blue } from '../colors'

function createMarkup(text) {
  return { __html: text }
}

const InnerHtmlWrapper = styled.div`
  ${(props) =>
    props.noStyle
      ? ``
      : `
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Dosis';
        font-weight: 700;
        color: ${fifth_black};
        padding: 0 0 24px;
        margin: 0;
    }
    h2 {
        font-size: 32px;
        line-height: 54px;
    }
    h3 {
        font-size: 24px;
        line-height: 48px;
    }
    h4 {
        font-size: 22px;
        line-height: 42px;
    }
    h5 {
        font-size: 20px;
        line-height: 36px;
    }
    h6 {
        font-size: 19px;
        line-height: 30px;
    }

    p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        padding: 0 0 24px;
        margin: 0;
    }

    ul,
    ol {
        margin: 0;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        font-style: italic;
    }
    `}
  a {
    color: ${link_blue};
    text-decoration: underline;
  }
`

export const innerHtml = (input, noStyle = false) => {
  return (
    <InnerHtmlWrapper
      className="innerHtml"
      noStyle={noStyle}
      dangerouslySetInnerHTML={createMarkup(input)}
    />
  )
}
