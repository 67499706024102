import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../../elements/typography'

const Wrapper = styled.div`
  padding: 20px 62px;
  background-color: white;

  @media screen and (max-width: 1100px) {
    margin: 0px 20px;
  }
`

const SubsiteTitleBlock = ({ title }) => {
  return (
    <Wrapper>
      <TypographyElement variant="subsiteTitle">{title}</TypographyElement>
    </Wrapper>
  )
}

export default SubsiteTitleBlock
