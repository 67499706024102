import React from 'react'
import styled from 'styled-components'

import Typography from '../../elements/typography'

import Breadcrumbs from '../../compositions/breadcrumbs'
import Logo from '../../elements/logo'
import ButtonElement from '../../elements/buttons'
import Width from '../../elements/widthContainer'

// Socials
import one from './icons/1.svg'
import two from './icons/2.svg'
import three from './icons/3.svg'
import four from './icons/4.svg'

// gementeimage
import gemeente from './icons/gemente.svg'
import { ImageBuilderFrontend } from '../../elements/imageBuilder'

// Styled component
const FooterWrapper = styled.footer`
  background-color: #f9f9f9;
  margin-top: 20px;

  .breadcrumbsWrapper {
    position: relative;
    top: -32px;
  }

  .contentWrapper {
    padding: 140px 0 40px;

    @media screen and (max-width: 1000px) {
      padding: 60px 0 40px;
    }
  }

  .logo {
    padding-bottom: 30px;
    width: 240px;
    @media screen and (max-width: 340px) {
      max-width: 100%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .content > div {
    width: 22%;
    display: inline-block;
    vertical-align: top;

    &:nth-child(4) {
      width: 32%;
    }

    .gemeenteImage {
      width: 204px;
      height: 104px;
      position: relative;
      display: block;
      > span {
        width: 100% !important;
        height: 100% !important;
        position: relative !important;
        display: inline-block !important;
      }
    }

    @media screen and (max-width: 1060px) {
      width: 33%;

      &:nth-child(4) {
        width: 100%;
      }
    }

    @media screen and (max-width: 740px) {
      width: 45%;

      &:nth-child(4) {
        width: 100%;
      }
    }

    @media screen and (max-width: 640px) {
      width: 100%;

      &:nth-child(4) {
        width: 100%;
      }
    }

    h3 {
      padding-bottom: 10px;
    }

    p {
      padding: 4px 0;
    }

    > div {
      padding-bottom: 60px;

      @media screen and (max-width: 1000px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &.lesserpad {
        padding-bottom: 20px;
      }

      &.MuiTypography-root {
        padding: 0;
      }
    }
  }

  .iconholder {
    display: inline-block;
    margin: 0 10px 0 0;
    background: #2e842a;
    width: 29px;
    height: 29px;
    line-height: 37px;
    text-align: center;
    position: relative;

    .three,
    .four {
      position: relative;
      top: -2px;
    }
    > span.linkWrapper > a {
      width: 29px;
      height: 29px;
      display: block;

      > img {
        padding: 4px !important;
        object-fit: contain !important;
      }
      &:focus,
      &:hover {
        background-color: #007aff !important;
        img {
          background-color: #007aff !important;
        }
      }

      &:focus-visible {
        outline: 4px solid #000 !important;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .footerBottom {
    a {
      padding: 0 8px;

      &:nth-child(1) {
        padding-left: 0;
      }
    }

    > div {
      width: 50%;
      display: inline-block;
      vertical-align: top;

      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: right;
      }

      @media screen and (max-width: 740px) {
        width: 100%;
        text-align: left !important;
        padding: 8px 0;
      }
    }
  }

  h2 {
    margin: 0;
  }

  @media screen and (max-width: 1000px) {
    .ctaContainer {
      padding: 40px 0 !important;
    }
  }
  @media screen and (max-width: 340px) {
    .ctaContainer {
      a {
        text-align: center;
      }
    }
  }
`

const FooterSection = ({
  breadcrumbs,
  column1,
  column2,
  column3,
  column4,
  cta,
  contact,
  noLinks = false,
}) => {
  const currYear = new Date().getFullYear()

  const contactTelephone = contact?.telephone || '050 367 6767'
  const contactTelephoneNumber = contactTelephone
    .replaceAll('-', '')
    .replaceAll(' ', '')

  return (
    <FooterWrapper>
      <div className="breadcrumbsWrapper">
        {breadcrumbs && (
          <div className="bradcrumbsContainerFooter">
            {breadcrumbs && (
              <Breadcrumbs items={breadcrumbs} variant="footer" />
            )}
          </div>
        )}
        <Width>
          <div className="contentWrapper">
            <div className="logo">
              <h2>
                <Logo _overwriteAltText='Sport050 snelle sport-, aanbieder- en diensten links' />
              </h2>
            </div>
            <div className="content">
              <>
                <div>
                  {column1?.links && (
                    <div>
                      <Typography variant="footerColumnTitle">
                        {column1.title}
                      </Typography>

                      {column1.links?.map((link) => {
                        return (
                          <Typography key={link} variant="footerColumnItem">
                            {link}
                          </Typography>
                        )
                      })}
                    </div>
                  )}

                  <div className="lesserpad">
                    <Typography variant="footerColumnItemThick">
                      <a href={`tel:${contactTelephoneNumber}`}>
                        {' '}
                        {contactTelephone}{' '}
                      </a>
                    </Typography>
                    <Typography variant="footerColumnItemThick">
                      <a
                        href={`mailto:${contact?.email || 'info@sport050.nl'}`}
                      >
                        {' '}
                        {contact?.email || 'info@sport050.nl'}{' '}
                      </a>
                    </Typography>
                  </div>

                  <div>
                    <div className="iconholder">
                      {' '}
                      <ButtonElement variant="base" href={contact?.instagram}>
                        <ImageBuilderFrontend
                          image={one}
                          alt="instagram link"
                        />{' '}
                      </ButtonElement>
                    </div>
                    <div className="iconholder">
                      {' '}
                      <ButtonElement variant="base" href={contact?.facebook}>
                        <ImageBuilderFrontend
                          image={two}
                          className="two"
                          alt="Facebook link"
                          // height={21}
                          width={9}
                        />
                      </ButtonElement>{' '}
                    </div>
                    <div className="iconholder">
                      {' '}
                      <ButtonElement variant="base" href={contact?.twitter}>
                        <ImageBuilderFrontend
                          image={three}
                          alt="Twitter link"
                        />
                      </ButtonElement>{' '}
                    </div>
                    <div className="iconholder">
                      {' '}
                      <ButtonElement variant="base" href={contact?.youtube}>
                        <ImageBuilderFrontend
                          image={four}
                          alt="Link naar youtube"
                        />
                      </ButtonElement>{' '}
                    </div>
                  </div>
                </div>

                <div>
                  {column2?.links && (
                    <div>
                      <Typography variant="footerColumnTitle">
                        {column2.title}
                      </Typography>
                      {column2.links?.map((item) => (
                        <Typography key={item.text} variant="footerColumnItem">
                          <a href={item.href}> {item.text} </a>
                        </Typography>
                      ))}
                    </div>
                  )}
                </div>

                <div>
                  {column3?.links && (
                    <div>
                      <Typography variant="footerColumnTitle">
                        {column3.title}
                      </Typography>
                      {column3.links?.map((item) => (
                        <Typography key={item.text} variant="footerColumnItem">
                          <a href={item.href}> {item.text} </a>
                        </Typography>
                      ))}
                    </div>
                  )}

                  {column4?.links && (
                    <div>
                      <Typography variant="footerColumnTitle">
                        {column4.title}
                      </Typography>
                      {column4?.links?.map((item) => (
                        <Typography key={item.text} variant="footerColumnItem">
                          <a href={item.href}> {item.text} </a>
                        </Typography>
                      ))}
                    </div>
                  )}
                </div>
              </>
              <div>
                {cta?.link && (
                  <div className="ctaContainer">
                    <Typography variant="footerColumnTitle">
                      {cta.text}
                    </Typography>
                    <ButtonElement variant="primaryLink" href={cta.link.href}>
                      {cta.link.text}
                    </ButtonElement>
                  </div>
                )}
                <div>
                  <ImageBuilderFrontend
                    image={gemeente}
                    alt="Gemeente Groningen"
                    width={200}
                    height={104}
                  />
                </div>
              </div>
            </div>
          </div>
          {!noLinks && (
            <div className="footerBottom">
              <div>
                <a href="/algemene-voorwaarden"> Algemene voorwaarden </a> |
                <a href="/cookies"> Cookies </a> |
                <a href="/privacyverklaring"> Privacyverklaring </a>
              </div>
              <div>©{currYear} Sport050</div>
            </div>
          )}
        </Width>
      </div>
    </FooterWrapper>
  )
}
export default FooterSection
