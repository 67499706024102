import React from 'react'
import styled from 'styled-components'

import ImageBuilder, { ImageBuilderFrontend } from '../../elements/imageBuilder'

import TitleTextButtonCard from '../../compositions/titleTextButton'

import standardImage from './standard_popup_image.png'

const Wrapper = styled.div`
  width: 874px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;

  .imageContainer {
    display: block;
    width: 35%;
    > span {
      width: 100% !important;
      /* aspect-ratio: 1.6; */
      img {
        object-position: center;
        object-fit: cover;
        width: 100%;
      }
    }
  }
  .contentContainer {
    flex: 1;
    > div {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 890px) {
    display: block;
    width: 98%;

    .imageContainer,
    .contentContainer {
      display: block;
      width: 100%;
    }

    .contentContainer > div {
      top: 0;
      transform: none !important;
      padding: 20px !important;
    }

    .imageContainer {
      > span img {
        object-position: top center !important;
      }
    }
  }
`

const PopupMediaTextBlock = ({
  img,
  title,
  text,
  link,
  href,
  button_text = '',
  handleClose,
}) => {
  const useLink = link || {
    href: href,
    text: button_text,
  }
  return (
    <Wrapper>
      <div className="imageContainer">
        {img ? (
          <ImageBuilder image={img} width={309} height={441} />
        ) : (
          <ImageBuilderFrontend
            image={standardImage}
            width={309}
            height={441}
          />
        )}
      </div>
      <div className="contentContainer">
        <TitleTextButtonCard
          title={title}
          text={text}
          button={useLink}
          handleClose={handleClose}
          extraStyle={{
            '.button': {
              'text-align': 'right',
              'margin-top': '64px',
            },
          }}
        />
      </div>
    </Wrapper>
  )
}

export default PopupMediaTextBlock
