import React from 'react'

import SearchCard from '../../compositions/searchCtaLight'
import PopupModal from '../../elements/popup'

const ToastPopup = ({ popup, handleClose }) => {
  return (
    <PopupModal variant="lowerright" handleClose={handleClose}>
      <SearchCard {...popup} handleClose={handleClose} />
    </PopupModal>
  )
}

export default ToastPopup
