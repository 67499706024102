import React from 'react'

import SearchCard from '../../compositions/searchCtaLight'
import PopupModal from '../../elements/popup'

const FullscreenBottomPopup = ({ popup, handleClose }) => {
  return (
    <PopupModal variant="bottomfullscreen" handleClose={handleClose}>
      <SearchCard {...popup} handleClose={handleClose} />
    </PopupModal>
  )
}

export default FullscreenBottomPopup
