import React from 'react'

import FullscreenPopup from './fullscreen'
import FullscreenBottomPopup from './fullscreenBotom'
import ToastPopup from './toast'

const mapTypes = {
  fullscreen: FullscreenPopup,
  fullscreenbottom: FullscreenBottomPopup,
  toast: ToastPopup,
}

const Popup = ({ popup, handleClose }) => {
  if (!popup?.type) return null

  const PopupElement = mapTypes[popup?.type] || null
  if (!Popup) return null

  return <PopupElement popup={popup} handleClose={handleClose} />
}

export default Popup
