'use client'
import React from 'react'
import styled from 'styled-components'

import Logo from '../../elements/logo'
import { HeaderSubTitleWhite } from '../../elements/typography'
import ImageBuilder from '../../elements/imageBuilder'

const Header1 = styled.header`
  height: 648px;
  display: block;
  position: relative;
  overflow: hidden;

  > div.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover !important;
    }
  }

  > div.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 160px 0;
    text-align: center;

    // Effect
    background: rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 900px) {
      padding: 160px 30px;
    }
  }

  .logoarea {
    img {
      width: 500px !important;
      height: auto;

      @media screen and (max-width: 600px) {
        width: 320px !important;
      }
    }
  }
`

const HeaderModule = ({ background, text }) => {
  return (
    <Header1>
      <div className="bg">
        <ImageBuilder decorativeOnly={true} image={background} layout="fill" />
      </div>
      <div className="content">
        <div className="logoarea">
          {' '}
          <h1>
            <Logo
              variant="white"
              alt="Sport050: Hét platform voor bewegend Groningen"
            />{' '}
          </h1>
        </div>
        <div>
          {' '}
          <HeaderSubTitleWhite> {text} </HeaderSubTitleWhite>{' '}
        </div>
      </div>
    </Header1>
  )
}

export default HeaderModule
