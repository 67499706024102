import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../typography'
import SkewContainer from '../skewWrapper'

import arrow from './assets/arrow.png'
import { ImageBuilderFrontend } from '../imageBuilder'
import ButtonElement from '../buttons'

const HeaderVariant = styled.div`
  display: inline-block;
  vertical-align: top;

  padding: 8px 12px 8px 6px;
  opacity: 0.6;

  ${(props) => (props.isFirst ? 'padding-left: 0;' : '')}

  .container {
    display: inline-block;
  }
  .arrowContainer {
    margin-left: 14px !important;
    position: relative !important;
    top: -2px !important;
    display: inline-block;
    width: 4px !important;
    height: 8px !important;
    > span {
      position: relative !important;
      display: inline-block !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
  img {
    margin-left: 6px;
    position: relative;
    top: -2px;
  }
`

const FooterVariant = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 42px;
  line-height: 42px;

  span,
  div {
    color: #fff;
  }

  span {
    height: 40px;
    line-height: 40px;
    display: block;
    padding: 10px 0;
  }
`

const BeforeElement = styled.div`
  width: 22px;
  height: 100%;
  position: absolute;
  background: #000;
  top: 0;
  left: -6px;
  padding: 0;
  ${(props) => props.extraStyle};
`

const AfterElement = styled.div`
  width: 22px;
  height: 100%;
  position: absolute;
  background: #000;
  top: 0;
  right: -6px;
  padding: 0;
  ${(props) => props.extraStyle};
`

const extraStyling = {
  header: `
    
  `,
  footer: `
    background: #000;
  `,
}

// Footer breadcrumb
const FooterElement = ({
  variant = 'header',
  text,
  isLast,
  isFirst,
  background = null,
}) => {
  const Component = FooterVariant
  const extraStyle = background
    ? `background: ${background};`
    : extraStyling[variant]
  return (
    <SkewContainer
      style={extraStyle}
      containerStyling={`margin-right: 6px;`}
      BeforeElement={isFirst && variant === 'footer' && BeforeElement}
      AfterElement={isLast && variant === 'footer' && AfterElement}
    >
      <Component isLast={isLast} isFirst={isFirst} className="elementWrapper">
        <span className="container">
          <TypographyElement variant="breadcrumb">{text}</TypographyElement>
        </span>
        {!isLast && variant === 'header' && (
          <div className="imageContainer">
            <ImageBuilderFrontend image={arrow} alt={text} />
          </div>
        )}
      </Component>
    </SkewContainer>
  )
}

// Header breadcrumb
const HeaderElement = ({ variant = 'header', text, isLast, isFirst }) => {
  const Component = HeaderVariant

  return (
    <Component isLast={isLast} isFirst={isFirst} className="elementWrapper">
      <span className="container">
        <TypographyElement variant="breadcrumb">{text}</TypographyElement>
      </span>
      {!isLast && variant === 'header' && (
        <div className="arrowContainer">
          <ImageBuilderFrontend image={arrow} alt="" />
        </div>
      )}
    </Component>
  )
}

const variantTable = {
  header: HeaderElement,
  footer: FooterElement,
}

// Export
const Breadcrumb = (props) => {
  const Component = variantTable[props.variant]
  if (props.href) {
    return (
      <ButtonElement variant="base" href={props.href}>
        <Component {...props} />
      </ButtonElement>
    )
  }
  return <Component {...props} />
}
export default Breadcrumb
