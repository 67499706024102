import React from 'react'
import styled from 'styled-components'

import Menu from '../modules/menu'
import Header from '../modules/header'

const HeaderWrapper = styled.div``

const HeaderSection = ({
  header,
  menu,
  headerImage,
  logo = null,
  text,
  headerVariant,
  menuItems,
  contact,
  noLinks = false,
  pageClass,
  headerLoadingVariant,
}) => {
  return (
    <HeaderWrapper>
      <Menu {...menu} bigmenu={menuItems} contact={contact} noLinks={noLinks} />
      {pageClass !== '404' && !headerLoadingVariant && (
        <Header
          {...header}
          headerImage={headerImage}
          logo={logo}
          text={text}
          variant={headerVariant}
        />
      )}
    </HeaderWrapper>
  )
}
export default HeaderSection
