'use client'
import React from 'react'
import { useParams } from 'next/navigation'
import styled from 'styled-components'

import Typography from '../../elements/typography'

import Breadcrumbs from '../../compositions/breadcrumbs'
import Logo from '../../elements/logo'
import ButtonElement from '../../elements/buttons'
import Width from '../../elements/widthContainer'

// Socials
import one from './icons/1.svg'
import two from './icons/2.svg'
import three from './icons/3.svg'
import four from './icons/4.svg'

// gementeimage
import gemeente from './icons/gemeente.svg'
import whiteLogo from '@/core/elements/logo/assets/white.png'
import { primary_orange, subsite_dark_background, white } from '../../colors'
import TypographyElement from '../../elements/typography'
import { ImageBuilderFrontend } from '../../elements/imageBuilder'

// Styled component
const FooterWrapper = styled.footer`
  background: ${subsite_dark_background};
  margin-top: 20px;

  .breadcrumbsWrapper {
    position: relative;
    top: -32px;
  }

  .contentWrapper {
    padding: 140px 0 40px;

    @media screen and (max-width: 1000px) {
      padding: 80px 0 20px;
    }
  }

  .logo {
    padding-bottom: 63px;
    @media screen and (max-width: 340px) {
      max-width: 100%;
    }
  }

  .content > div {
    width: 22%;
    display: inline-block;
    vertical-align: top;

    &:nth-child(4) {
      width: 32%;
    }

    @media screen and (max-width: 1100px) {
      width: 50% !important;
    }

    @media screen and (max-width: 700px) {
      width: 100% !important;
    }

    h3 {
      padding-bottom: 10px;
    }

    p {
      padding: 4px 0;
    }

    > div {
      padding-bottom: 60px;

      @media screen and (max-width: 1000px) {
        padding-bottom: 40px;
      }

      &.lesserpad {
        padding-bottom: 20px;
      }
    }
    @media screen and (max-width: 340px) {
      .ctaContainer {
        a {
          text-align: center;
        }
      }
    }
  }

  .iconholder {
    display: inline-block;
    margin: 0 10px 0 0;
    background: ${white};
    width: 29px;
    height: 29px;
    line-height: 37px;
    text-align: center;
    position: relative;

    .three,
    .four {
      position: relative;
      top: -2px;
    }
    > span.linkWrapper > a > img {
      padding: 4px !important;
      object-fit: contain !important;
    }

    a {
      &:focus {
        display: inline-block;
        width: 29px;
        height: 29px;
      }
    }
  }

  .footerBottom {
    color: ${white};
    font-family: 'Poppins';
    font-weight: 500;
    a {
      padding: 0 8px;

      &:nth-child(1) {
        padding-left: 0;
      }
    }

    > div {
      width: 50%;
      display: inline-block;
      vertical-align: top;

      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: right;
      }
    }
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: start;
    gap:40px;

    img {
      max-width: calc(50% - 20px);
      object-fit: contain !important;
    }

    @media screen and (max-width: 1000px) {
      align-items: baseline;
      flex-direction: column;
      gap: 20px;
      img {
        max-width: 100%;
      }
    }
  }
`

const SubsiteFooterSection = ({
  sitetitle,
  breadcrumbs,
  column1,
  column2,
  column3,
  column4,
  cta,
  contact,
}) => {
  const currYear = new Date().getFullYear()

  const contactTelephone = contact?.telephone || '050 367 6767'
  const contactTelephoneNumber = contactTelephone
    .replaceAll('-', '')
    .replaceAll(' ', '')
  const { location } = useParams()
  const subsiteUrl = `/sportlocaties/intern/${location}/`
  return (
    <FooterWrapper>
      <div className="breadcrumbsWrapper">
        {breadcrumbs && (
          <Breadcrumbs
            items={breadcrumbs}
            variant="footer"
            background={primary_orange}
          />
        )}
        <Width>
          <div className="contentWrapper">
            <div className="logo">
              {sitetitle ? (
                <ButtonElement variant="base" href={subsiteUrl}>
                  <TypographyElement variant="subsiteHeaderTitle">
                    {sitetitle}
                  </TypographyElement>
                </ButtonElement>
              ) : (
                <Logo _overwriteAltText='' />
              )}
            </div>
            <div className="content">
              <>
                <div>
                  {column1?.links && (
                    <div>
                      <Typography variant="footerColumnTitle">
                        {column1.title}
                      </Typography>

                      {column1.links?.map((link) => {
                        return (
                          <Typography key={link} variant="footerColumnItem">
                            {link}
                          </Typography>
                        )
                      })}
                    </div>
                  )}

                  <div className="lesserpad">
                    <Typography variant="footerColumnItemThick">
                      <a href={`tel:${contactTelephoneNumber}`}>
                        {' '}
                        {contactTelephone}{' '}
                      </a>
                    </Typography>
                    <Typography variant="footerColumnItemThick">
                      <a href={`mailto:${contact?.email}`}>
                        {' '}
                        {contact?.email || 'info@sport050.nl'}{' '}
                      </a>
                    </Typography>
                  </div>

                  <div>
                    <div className="iconholder">
                      {' '}
                      <ButtonElement variant="base" href={contact?.instagram}>
                        <ImageBuilderFrontend
                          image={one}
                          alt="instagram link"
                        />{' '}
                      </ButtonElement>
                    </div>
                    <div className="iconholder">
                      {' '}
                      <ButtonElement variant="base" href={contact?.facebook}>
                        <ImageBuilderFrontend
                          image={two}
                          className="two"
                          alt="Facebook link"
                        />
                      </ButtonElement>{' '}
                    </div>
                    <div className="iconholder">
                      {' '}
                      <ButtonElement variant="base" href={contact?.twitter}>
                        <ImageBuilderFrontend
                          image={three}
                          alt="Twitter link"
                        />
                      </ButtonElement>{' '}
                    </div>
                    <div className="iconholder">
                      {' '}
                      <ButtonElement variant="base" href={contact?.youtube}>
                        <ImageBuilderFrontend
                          image={four}
                          alt="Link naar youtube"
                        />
                      </ButtonElement>{' '}
                    </div>
                  </div>
                </div>

                <div>
                  {column2?.links && (
                    <div>
                      <Typography variant="footerColumnTitle">
                        {column2.title}
                      </Typography>
                      {column2.links?.map((item) => (
                        <Typography key={item.text} variant="footerColumnItem">
                          <a href={item.href}> {item.text} </a>
                        </Typography>
                      ))}
                    </div>
                  )}
                </div>

                <div>
                  {column3?.links && (
                    <div>
                      <Typography variant="footerColumnTitle">
                        {column3.title}
                      </Typography>
                      {column3.links?.map((item) => (
                        <Typography key={item.text} variant="footerColumnItem">
                          <a href={item.href}> {item.text} </a>
                        </Typography>
                      ))}
                    </div>
                  )}

                  {column4?.links && (
                    <div>
                      <Typography variant="footerColumnTitle">
                        {column4.title}
                      </Typography>
                      {column4?.links?.map((item) => (
                        <Typography key={item.text} variant="footerColumnItem">
                          <a href={item.href}> {item.text} </a>
                        </Typography>
                      ))}
                    </div>
                  )}
                </div>
              </>
              <div>
                {cta?.link && (
                  <div className="ctaContainer">
                    <Typography variant="footerColumnTitle">
                      {cta.text}
                    </Typography>
                    <ButtonElement variant="whiteLink" href={cta.link.href}>
                      {cta.link.text}
                    </ButtonElement>
                  </div>
                )}
                <div className="logoContainer">
                  <ImageBuilderFrontend
                    image={whiteLogo}
                    alt="Sport050 logo"
                    width={200}
                    height={104}
                  />
                  <ImageBuilderFrontend
                    image={gemeente}
                    alt="Gemeente Groningen"
                    width={200}
                    height={104}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footerBottom">
            <div>
              <a href="/algemene-voorwaarden"> Algemene voorwaarden </a> |
              <a href="/cookies"> Cookies </a> |
              <a href="/privacyverklaring"> Privacyverklaring </a>
            </div>
            <div>©{currYear} Sport050</div>
          </div>
        </Width>
      </div>
    </FooterWrapper>
  )
}
export default SubsiteFooterSection
