'use client'
// @INFO
//
// This wraps on a page level, because it needs page related props / logic
//
import PopupWrapper from '@/core/popupWrapper'
import PageWrapperMainSite, { PageWrapperSubSite } from '@/core/pageWrapper'

// MAIN SITE VS SUBSITE WRAPPER
const Wrapper = ({
  children,
  isSubsite,
  pageProps,
  pageClass,
  headerLoadingVariant,
}) => {
  const props = { ...pageProps }

  if (!isSubsite) {
    return (
      <PageWrapperMainSite
        {...props}
        pageClass={pageClass}
        headerLoadingVariant={headerLoadingVariant}
      >
        {children}
      </PageWrapperMainSite>
    )
  }
  return (
    <PageWrapperSubSite
      {...props}
      pageClass={pageClass}
      headerLoadingVariant={headerLoadingVariant}
    >
      {children}
    </PageWrapperSubSite>
  )
}

const PageWrapper = ({
  isSubsite,
  popups,
  children,
  pageClass,
  pageProps,
  headerLoadingVariant,
}) => {
  return (
    <Wrapper
      isSubsite={isSubsite}
      pageProps={pageProps}
      pageClass={pageClass}
      headerLoadingVariant={headerLoadingVariant}
    >
      {children}
      {!headerLoadingVariant && (
        <PopupWrapper popups={popups || []} pageclass={pageClass} />
      )}
    </Wrapper>
  )
}

export default PageWrapper
