import React from 'react'
import styled from 'styled-components'
import Breadcrumb from '../../elements/breadcrumbs'
import Width from '../../elements/widthContainer'
import Colorbar from '../../elements/colorbars'

const BreadcrumbsWrapper = styled.div`
  div.pos {
    position: relative;
    bottom: -30px;

    .breadcrumbsHolder {
      position: absolute;
      top: -12px;
      left: 0;
      z-index: 999;
    }
  }

  @media screen and (max-width: 1060px) {
    .mobBreadWrap.footer {
      display: none;
    }
  }
`

const breadcrumbItem = (items, variant, background = null) => {
  return items?.map((item, index) => (
    <Breadcrumb
      key={`${variant}-breadcrumb-${index}`}
      isFirst={index === 0}
      isLast={index === items.length - 1}
      variant={variant}
      text={item.text}
      href={item.href}
      background={background}
    />
  ))
}

const BreacrumbComposition = ({ items, variant, background = null }) => {
  const Breadcrumbs = breadcrumbItem(items, variant, background)

  if (variant === 'footer') {
    return (
      <BreadcrumbsWrapper>
        <div className="pos">
          <Colorbar>
            <Width relative>
              <div className="breadcrumbsHolder mobBreadWrap footer">
                {Breadcrumbs}
              </div>
            </Width>
          </Colorbar>
        </div>
      </BreadcrumbsWrapper>
    )
  }

  return (
    <BreadcrumbsWrapper>
      <div className="mobBreadWrap"> {Breadcrumbs} </div>
    </BreadcrumbsWrapper>
  )
}

export default BreacrumbComposition
