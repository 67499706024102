import React from 'react'
import styled from 'styled-components'

import WidthContainer from '../../elements/widthContainer'
import { HeaderTitleWhite } from '../../elements/typography'
import ImageBuilder from '../../elements/imageBuilder'

const Header1 = styled.header`
  height: 352px;
  display: block;
  position: relative;
  overflow: hidden;
  background: linear-gradient(0deg, #3baa34, #3baa34),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-blend-mode: multiply, normal, normal;
  background-size: cover;
  background-position: center center;

  > div.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover !important;
      mix-blend-mode: darken !important;
    }
  }

  div.effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  div.noneEffect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  div.whitebar {
    position: absolute;
    width: 120%;
    height: 400px;
    background: #fff;
    left: -2%;
    bottom: -340px;
    z-index: 999999;
    -webkit-transform: rotate(-2.06deg);
    -ms-transform: rotate(-2.06deg);
    transform: rotate(-2.06deg);
  }

  div.fade {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;

    background: rgba(255, 255, 255, 0.5);
  }

  .bigtext {
    padding-top: 40px;
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 600;
    font-size: 206.374px;
    line-height: 261px;
    color: rgba(255, 255, 255, 0.6);
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 6px;
  }

  .regulartext {
    padding-left: 0px;
    position: absolute;
    bottom: -280px;
  }

  .textWrapper {
    position: relative;
    z-index: 9999;
  }

  // Mobile
  @media screen and (max-width: 900px) {
    .regulartext {
      h1 {
        font-size: 60px !important;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .regulartext {
      h1 {
        font-size: 50px !important;
      }
    }
  }
  @media screen and (max-width: 420px) {
    .regulartext {
      h1 {
        font-size: 40px !important;
      }
    }
  }
`

const HeaderModule = ({ background, text, withFade, withEffect = true }) => {
  return (
    <Header1 background={background}>
      <div className="bg">
        <ImageBuilder decorativeOnly={true} image={background} layout="fill" />
      </div>
      <div className={withEffect ? 'effect' : 'noneEffect'}>
        <WidthContainer variant="full" relative>
          <div className="textWrapper">
            <div className="regulartext">
              <HeaderTitleWhite>{text}</HeaderTitleWhite>
            </div>
          </div>
        </WidthContainer>
      </div>
      <div className="whitebar"></div>
      {withFade && <div className="fade"> </div>}
    </Header1>
  )
}

export default HeaderModule
