import { useEffect, useState } from 'react'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    pageWidth: 0,
    pageHeight: 0,
  })

  useEffect(() => {
    window.removeEventListener('resize', handleResize)

    function handleResize() {
      setWindowSize({
        pageWidth: window.innerWidth,
        pageHeight: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
export default useWindowSize
