import React from 'react'

import PopupModal from '../../elements/popup'
import PopupMediaTextBlock from '../popupMediaText'

const FullscreenPopup = ({ popup, handleClose }) => {
  return (
    <PopupModal variant="fullscreen" handleClose={handleClose}>
      <PopupMediaTextBlock {...popup} handleClose={handleClose} />
    </PopupModal>
  )
}

export default FullscreenPopup
