'use client'
import React from 'react'
import { usePathname } from 'next/navigation'
import styled from 'styled-components'

import { useTheme } from '@mui/material'

import TypographyElement from '../../../elements/typography'
import ButtonElement from '../../../elements/buttons'
import SubsiteTitleBlock from '../../../compositions/_subsite/subsiteTitle'
import SubsiteCta from '../../../compositions/_subsite/cta'
import BigMenu from '../../menu/bigmenu'
import ImageBuilder from '../../../elements/imageBuilder'
import ColorBar from '../../../elements/colorbars'

const HeaderContainer = styled.header`
    padding: 30px 50px 0;

    @media screen and (max-width: 1200px) {
        padding: 50px 20px 0;
    }

    .logoContainer {
        display: inline-block;
        vertical-align: middle;
        width: 235px;
        margin-right: 38px;
        padding-bottom: 20px;
        > * {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .siteContainer {
        display: inline-block;
        vertical-align: middle;
        width: calc(100%);
        background-color: ${(props) =>
          props.backgroundColor ? props.backgroundColor : 'transparent'};
        border-radius: 5px 5px 0 0;
        padding: 25px 52px;
        .titleContainer {
            display: inline-block;
            vertical-align: middle;
            width: 40%;

            a {
                display: inline-block;
            }
            
            h1 {
                font-size: 30px;
            }
        }
        .menu {
            display: none !important;
            vertical-align: middle;
            float: right;
            .searchIcon {
                display: inline-block;
                vertical-align: middle;
                width: 30px;

                div {
                    height: 2px;
                    margin-bottom: 4px;
                    background: white;
                    width: 20px;
                    float: right;

                    &:last-child {
                        width: 20px;
                    }
                }
            }
        }
        .contentContainer {
            display: inline-block;
            vertical-align: middle;
            width: 60%;
            text-align: right;
            > * {
                margin-left: 34px;
                line-height: 50px;
                :last-child {
                    margin-left: 53px;
                }
            }
        }

        @media screen and (max-width: 1000px) {
            > div {
                display: none !important;
            }
            .titleContainer {
                display: inline-block !important;
                width: unset;
                h1 {
                    font-size: 30px !important;
                }
            }
            .menu {
                display: inline-block !important;
                padding-top: 8px;
                color: white;
                cursor: pointer;
                position: absolute;
                right: 80px;
            }
        }
        @media screen and (max-width: 700px) {
            padding: 18px 36px !important;
            .titleContainer {
                display: inline-block !important;
                width: unset;
                h1 {
                    font-size: 20px !important;
                }
            }
            .menu {
                padding-top: 3px;
            }
        }
    }

    @media screen and (max-width: 750px) {
        .siteContainer,
        .logoContainer {
            width: 100%;
            margin-right: 0;
        }

        .siteContainer {
            .menu {
                right: 50px;
            }
        }

        .logoContainer {
            padding-bottom: 20px;
        }
    }

    .subsiteInfo {
        margin-top: 0px;
        margin-bottom: 30px;
        display: block;
        position: relative;
        border-radius: 5px;

        > div.bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            img {
                object-fit: cover !important;
            }
        }

        ${(props) =>
          props.isHome
            ? `
            height: 345px;
            .shade {
                .subsiteTitle {
                    position: absolute;
                    left: 232px;
                    bottom: 128px;

                    @media screen and (max-width: 1300px) {
                        width: 810px;
                        left: 50%;
                        margin-left: -400px;
                    }

                    @media screen and (max-width: 1100px) {
                        width: 100%;
                        left: 0%;
                        margin-left: 0px;
                        h1 {
                            font-size: 60px;
                            padding-right: 10%;
                        }
                    }
                }
                .subsiteCta {
                    position: absolute;
                    right: 245px;
                    bottom: 0;
                    transform: translateY(50%);

                    @media screen and (max-width: 1500px) {
                        right: 0;
                        width: 100%;
                    }
                }
            }
        `
            : `
            height: 345px;
            .shade {
                background: rgba(0, 0, 0, 0.3);
                position: relative;
                width: 100%;
                height: 100%;
                .subsiteTitle {
                    position: absolute;
                    left: 16%;
                    top: 105px;
                    width: 84%;

                    @media screen and (max-width: 1000px) {
                        h1 {
                            font-size: 50px;
                            padding-right: 10%;
                        }
                    }

                    @media screen and (max-width: 800px) {
                        left: 20px;
                        width: calc(100% - 40px);
                    }

                    @media screen and (max-width: 600px) {
                        h1 {
                            word-break: break-word;
                            font-size: 46px;
                        }
                    }
                }
                .subsiteCta {
                    display: none;
                }
            }
        `}

        @media screen and (max-width: 1000px) {
            .textContainer {
                display: none;
            }

            .buttonContainer {
                display: block;
                width: 100%;

                a {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        ${(props) =>
          props.isHome &&
          `.subsiteInfo {
            height: unset;
            div.bg {
                height: 300px !important;
                display: block;
                position: relative !important;
            }
            .shade {
                .subsiteTitle {
                    display: block;
                    position: relative;
                    bottom: unset;
                    > div {
                        margin: 20px 0;
                        padding: 0;
                    }
                    h1 {
                        margin: 0;
                        padding: 0;
                        text-align: center;
                    }
                }
                .subsiteCta {
                    display: block;
                    position: relative;
                    top: unset;
                    bottom: unset;
                    right: unset;
                    left: unset;
                    transform: unset;
                    > div {
                        padding: 18px 24px;
                    }
                }
            }
        }`}
    @media screen and (max-width: 550px) {
        ${(props) =>
          props.isHome &&
          `.subsiteInfo {
                    .shade {
                        .subsiteTitle {
                            h1 {
                                margin: 0;
                                padding: 0;
                                font-size: 50px;
                            }
                        }
                    }
                }`}
    @media screen and (max-width: 420px) {
        ${(props) =>
          props.isHome
            ? `.subsiteInfo {
                    .shade {
                        .subsiteTitle {
                            h1 {
                                margin: 0;
                                padding: 0;
                                font-size: 40px;
                                word-wrap: break-word;
                            }
                        }
                    }
                }`
            : `.subsiteInfo {
                    .shade {
                        .subsiteTitle {
                            h1 {
                                font-size: 36px;
                            }
                        }
                    }
                }
                `}
`

const getSubsiteMenu = (sitename) => {
  return [
    {
      title: '',
      items: [
        {
          text: 'Openingstijden',
          href: `/sportlocaties/intern/${sitename}/openingstijden`,
        },
        {
          text: 'Tarieven',
          href: `/sportlocaties/intern/${sitename}/tarieven`,
        },
        {
          text: 'Activiteiten',
          href: `/sportlocaties/intern/${sitename}/activiteiten`,
        },
        {
          text: 'Faciliteiten',
          href: `/sportlocaties/intern/${sitename}/faciliteiten`,
        },
      ],
    },
  ]
}

const SubsiteHeader = ({
  isHome = false,
  pages,
  title,
  sitename,
  sitetitle,
  headerImage,
  headerCta,
  mainLink,
  contact,
  slug,
}) => {
  const route = usePathname()
  const [isOpen, setIsOpen] = React.useState(false)
  const theme = useTheme()
  const color = theme.globalTheme?.palette?.backgroundColor?.dark
  // Route is in shape "/sportlocaties/intern/[siteslug]/[rest]"
  const siteslug = route?.split('/')[3] || slug || ''
  const subsiteUrl = `/sportlocaties/intern/${siteslug}/`
  return (
    <HeaderContainer backgroundColor={color} isHome={isHome}>
      <div className="siteContainer">
        <div className="titleContainer">
          <ButtonElement variant="base" href={subsiteUrl}>
            <TypographyElement variant="subsiteHeaderTitle" component="h1">
              {sitetitle || sitename}
            </TypographyElement>
          </ButtonElement>
        </div>
        <div className="menu" onClick={() => setIsOpen(true)}>
          <div className="searchIcon">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="contentContainer">
          {pages ? (
            pages?.map((page, index) => {
              return (
                <ButtonElement
                  key={`button-subsite-header-${index}`}
                  variant="subsiteHeaderLink"
                  href={page.href}
                >
                  {page.title}
                </ButtonElement>
              )
            })
          ) : (
            <>
              <ButtonElement
                key={`button-subsite-header-${1}`}
                variant="subsiteHeaderLink"
                href={subsiteUrl + 'openingstijden'}
              >
                Openingstijden
              </ButtonElement>
              <ButtonElement
                key={`button-subsite-header-${2}`}
                variant="subsiteHeaderLink"
                href={subsiteUrl + 'tarieven'}
              >
                Tarieven
              </ButtonElement>
              <ButtonElement
                key={`button-subsite-header-${3}`}
                variant="subsiteHeaderLink"
                href={subsiteUrl + 'activiteiten'}
              >
                Activiteiten
              </ButtonElement>
              <ButtonElement
                key={`button-subsite-header-${3}`}
                variant="subsiteHeaderLink"
                href={subsiteUrl + 'faciliteiten'}
              >
                Faciliteiten
              </ButtonElement>
            </>
          )}
          {mainLink ? (
            <ButtonElement variant="primaryLink" href={mainLink.href}>
              {mainLink.text}
            </ButtonElement>
          ) : null}
        </div>
      </div>
      <ColorBar variant="long" />
      <div className="subsiteInfo">
        {headerImage?.url && (
          <div className="bg">
            <ImageBuilder
              decorativeOnly={true}
              image={headerImage?.url}
              layout="fill"
            />
          </div>
        )}
        {isHome ? (
          <div className="shade">
            <div className="subsiteTitle">
              <SubsiteTitleBlock title={title} isHome={isHome} />{' '}
            </div>
            {headerCta && (
              <div className="subsiteCta">
                <SubsiteCta text={headerCta.text} link={headerCta.link} />
              </div>
            )}
          </div>
        ) : (
          <div className="shade">
            <div className="subsiteTitle">
              <TypographyElement variant="headerTitleWhite">
                {title}
              </TypographyElement>
            </div>
          </div>
        )}
      </div>
      <BigMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={getSubsiteMenu(siteslug)}
        contact={contact}
        isSubsite={true}
      />
    </HeaderContainer>
  )
}

export default SubsiteHeader
