'use client'
import React from 'react'
import styled from 'styled-components'

import { ThemeProvider } from '@mui/material/styles'

// SubSite
import subTheme from './materialThemeSubSite'

// MainSite
import Header from './sections/header'
import Footer from './sections/footer'
import SubsiteHeader from './modules/_subsite/header'
import SubsiteFooterSection from './subsiteSections/footer'

const PageWrapper = styled.div`
  overflow: hidden;
  .mainContentArea {
    padding-bottom: ${(props) => `${props.padding}`};

    @media screen and (max-width: 1000px) {
      padding-bottom: 40px;
    }
  }
`

export const PageWrapperMainSite = (props) => {
  const {
    header,
    headerImage,
    header_image,
    logo = null,
    footer,
    children,
    addPadding = 0,
    headerText,
    headerVariant,
    breadcrumbs,
    contact,
    noLinks = false,
    pageClass,
    headerLoadingVariant,
  } = props

  return (
    <PageWrapper padding={addPadding}>
      <Header
        {...header}
        menuItems={header}
        headerImage={props.header_image || props.headerImage}
        logo={logo}
        text={headerText}
        headerVariant={headerVariant}
        contact={contact}
        noLinks={noLinks}
        pageClass={pageClass}
        headerLoadingVariant={headerLoadingVariant}
      />
      <div className="mainContentArea">{children}</div>
      {!headerLoadingVariant && (
        <Footer
          {...footer}
          contact={contact}
          breadcrumbs={breadcrumbs}
          noLinks={noLinks}
        />
      )}
    </PageWrapper>
  )
}

export const PageWrapperSubSite = ({
  header,
  headerImage,
  headerCta,
  footer,
  children,
  addPadding = 0,
  headerText,
  headerVariant,
  breadcrumbs,
  sitename,
  sitetitle,
  isHome,
  mainLink,
  contact,
  slug,
}) => {
  return (
    <PageWrapper>
      <ThemeProvider theme={subTheme}>
        <SubsiteHeader
          {...header}
          header={header}
          menuItems={header}
          headerImage={headerImage}
          title={headerText}
          headerVariant={headerVariant}
          sitename={sitename}
          sitetitle={sitetitle}
          isHome={isHome}
          headerCta={headerCta}
          mainLink={mainLink}
          contact={contact}
          slug={slug}
        />
        <div className="mainContentArea">{children}</div>
        <SubsiteFooterSection
          {...footer}
          contact={contact}
          breadcrumbs={breadcrumbs}
          sitename={sitename}
          sitetitle={sitetitle}
        />
      </ThemeProvider>
    </PageWrapper>
  )
}

export default PageWrapperMainSite
