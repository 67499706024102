import React from 'react'
import styled from 'styled-components'
import ImageBuilder from '../../elements/imageBuilder'
import WidthContainer from '../../elements/widthContainer'

const HeaderClub = styled.header`
  height: 352px;
  display: block;
  position: relative;
  overflow: hidden;
  background: #fff;
  background-size: cover;
  background-position: top center;

  > div.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover !important;
    }
  }

  > div.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 0;
    text-align: center;

    // Effect
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;

    .clublogo {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 365px;
      padding: 0px 60px 55px 0px;
      background: #fff;
      box-shadow: 0px 0px 130px 180px rgba(255, 255, 255, 1);

      .imageContainer {
        position: relative;
        top: 60px;
      }
    }
  }

  // Mobile
  @media screen and (max-width: 900px) {
  }
`

const HeaderModule = ({ background, img, text }) => {
  return (
    <HeaderClub background={background}>
      <div className="bg">
        <ImageBuilder decorativeOnly={true} image={background} layout="fill" />
      </div>
      <div className="content">
        <WidthContainer>
          <div style={{ position: 'relative' }}>
            <div className="clublogo">
              <div className="imageContainer">
                <ImageBuilder image={img} alt={text} width={242} height={242} />
              </div>
            </div>
          </div>
        </WidthContainer>
      </div>
    </HeaderClub>
  )
}

export default HeaderModule
