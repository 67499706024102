import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import { innerHtml } from '../../utils/innerHtml'
import { white } from '../../colors'

const overlapStyling = {
  padding: '39px 63px 39px 63px',
  background: white,
  boxSizing: 'border-box',
  maxWidth: '100%',
  'box-shadow':
    '0px 17px 98px rgba(0, 0, 0, 0.05), 0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946)',
  '.button': {
    'text-align': 'right',
    a: {
      'text-align': 'center',
    },
  },
}

const overlayAdditional = `
    @media screen and (max-width: 1000px) {
        padding: 20px;
    }
`

const normalLeftStyling = {
  padding: '0 60px 0 0',

  '.button': {
    'text-align': 'left',
    a: {
      'text-align': 'center',
    },
  },
}

const normalRightStyling = {
  padding: '40px',

  '.button': {
    'text-align': 'left',
    a: {
      'text-align': 'center',
    },
  },
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  ${(props) => props.extraStyling}
  ${(props) => props.extraStyle}
    ${(props) => props.inlineStyle}

    @media screen and (max-width: 600px) {
    .button {
      svg {
        display: none;
      }
    }
  }
`
const stylingDict = {
  left: normalLeftStyling,
  right: normalRightStyling,
  overlap: overlapStyling,
}

const TitleTextButtonCard = ({
  title,
  text,
  button,
  variant = 'right',
  extraStyle = false,
  handleClose,
}) => {
  const extraStyling =
    variant && variant in stylingDict ? stylingDict[variant] : normalLeftStyling

  return (
    <Wrapper
      extraStyling={extraStyling}
      extraStyle={extraStyle}
      inlineStyle={variant === 'overlap' ? overlayAdditional : ''}
    >
      <TypographyElement variant="blockTitle" component="h2">
        {title}
      </TypographyElement>
      <TypographyElement variant="p" component="div">
        {innerHtml(text)}
      </TypographyElement>
      {(button?.href || button?.external_url) && (
        <div className="button">
          <ButtonElement
            variant={button?.variant || 'primary'}
            onClick={() => {
              if (handleClose) {
                handleClose()
              }

              if (button?.external_url) {
                window.open(button.external_url, '_blank')
              } else if (button?.href) {
                window.location.href = button?.href
              }
            }}
          >
            {button?.text}
          </ButtonElement>
        </div>
      )}
    </Wrapper>
  )
}

export default TitleTextButtonCard
