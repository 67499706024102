'use client'
import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import ColorBar from '../../elements/colorbars'
import ImageBuilder from '../../elements/imageBuilder'

import useWindowSize from '../../utils/useResize'
import SkewContainer, { SkewImage } from '../../elements/skewWrapper'
import { InnerSkewedLink } from '../../elements/buttons'

const SearchCardWrapper = styled.div`
  position: relative;
  margin-bottom: 50px;

  @media screen and (max-width: 1300px) {
    min-width: 50vw;
  }

  .container {
    position: relative;
    height: 238px;
    padding: 22px 0;

    .titleText {
      white-space: normal !important;
      padding-right: 50px !important;
    }

    @media screen and (max-width: 1000px) {
      padding: 0;
    }

    > a {
      display: block;
    }
  }
  .imageContainer {
    display: inline-block;
    vertical-align: middle;
    width: auto;

    width: 300px;
    height: 200px;

    position: relative;
    left: -8px;

    .backgroundAsset {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: none;
    }

    img {
      object-fit: cover;
    }

    @media screen and (max-width: 1320px) {
      display: none;
    }

    width: 300px;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;

      img {
        left: -12%;
        transform: skew(18deg);
      }
    }
  }
  .textContainer {
    position: absolute;
    top: 30%;
    left: 480px;
    width: calc(100vw - 880px);

    @media screen and (max-width: 1600px) {
      top: 20px;
      left: 480px;
    }

    @media screen and (max-width: 1320px) {
      left: 60px;
      top: 60px;
      width: calc(100vw - 400px);
    }

    @media screen and (max-width: 1150px) {
      left: 40px;
      top: 20px;
      width: calc(100vw - 400px);
    }

    @media screen and (max-width: 1000px) {
      left: 20px;
      top: 20px;
      width: calc(100vw - 220px);
    }

    @media screen and (max-width: 800px) {
      .titleText {
        font-size: 26px !important;
      }
    }

    @media screen and (max-width: 550px) {
      .titleText {
        font-size: 22px !important;
      }

      left: 0px;
      top: 20px;
      width: calc(100vw - 113px);
    }

    max-width: 886px;
    p {
      padding: 0;
    }
  }
  .buttonContainer {
    position: absolute;
    ${(props) =>
      props.buttonUpperRight
        ? `
            right: -48px;
            top: 6px;

            @media screen and (max-width: 1000px) {
                right: -28px;
                top: 151px;
            }
        `
        : `
            right: 0;
            top: 60px;
            
        `}

    @media screen and (max-width: 550px) {
    }
  }
`

const ColorBarHolder = styled.div`
  position: absolute;
  bottom: -9px;
  left: -36px;
  width: 100%;
  height: 30px;
  @media screen and (max-width: 1000px) {
    left: 0;
  }
`

const Div = ({ children }) => {
  return <div>{children}</div>
}

const NonSkewedContainer = styled.div`
  display: none;
  background-color: #f9f9f9 !important;
  width: 100%;
  border: 1px solid #8c8c8c;
  box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
    0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
    0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
  margin-right: 1px;
  .searchCardWrapper {
    margin-bottom: 0;
  }
  .container {
    height: auto !important;
    .textContainer {
      display: block;
      position: relative;
      top: unset;
      left: unset;
      width: 100%;
      padding: 20px;
    }
  }
  .buttonContainer {
    position: absolute;
    top: unset;
    bottom: 20px;
    left: unset;
    right: 20px;
    .svgContainer {
      top: unset !important;
      right: unset;
      left: unset !important;

      svg {
        path {
          stroke: black;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    display: block;
  }
`

const SkewedWrapper = styled.div`
  display: block;
  @media screen and (max-width: 500px) {
    display: none;
  }
`

const NonSkewColorBarHolder = styled.div`
  width: 100%;
`

const AccessabilityWrapper = styled.div`
  > a {
    display: block;
  }
  > a:focus {
    outline-width: 2px;
    outline-style: auto;
  }
`

const SearchCard = ({
  title,
  href,
  img,
  search_img,
  text,
  buttonUpperRight = true,
  addShadow = true,
}) => {
  const { pageWidth } = useWindowSize()
  const image = search_img || img
  const LinkWrapper = href ? InnerSkewedLink : Div

  const isSmall = pageWidth <= 750

  return (
    <AccessabilityWrapper>
      <LinkWrapper href={href} title={title}>
        {isSmall ? (
          <NonSkewedContainer>
            <SearchCardWrapper className="searchCardWrapper">
              <div className="container">
                <div>
                  <div className="imageContainer"></div>
                  <div className="textContainer">
                    <TypographyElement
                      variant="searchCardTitle"
                      component="p"
                      className="titleText"
                    >
                      {title}
                    </TypographyElement>
                    {text && (
                      <TypographyElement variant="p">{text}</TypographyElement>
                    )}
                  </div>
                </div>
              </div>
              {href && (
                <div className="buttonContainer">
                  <ButtonElement variant="arrow" noLink />
                </div>
              )}
            </SearchCardWrapper>
            <NonSkewColorBarHolder>
              <div>
                <ColorBar
                  customStyling={`width: 100%; position: relative !important`}
                />
              </div>
            </NonSkewColorBarHolder>
          </NonSkewedContainer>
        ) : (
          <SkewedWrapper>
            <SkewContainer
              displayBlock
              skew={pageWidth < 1000 ? '' : '-18.88deg'}
              style={`
                    background-color: #F9F9F9;
                    display: block;
                    height: 238px;
                    width: 100%;
                    border:1px solid #8C8C8C;
                    ${
                      addShadow &&
                      'box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05), 0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946)'
                    };

                    @media screen and (max-width: 1000px) {
                        margin-right: 1px;
                        width: calc(100% - 3px);
                    }
                `}
              containerStyling={`
                    padding: 0;
                    width: 100%;
                    height: 238px;
                    padding: 0px 50px;
                    overflow: hidden;
                    margin-bottom: 50px;

                    @media screen and (max-width: 1000px) {
                        padding: 0;
                    }

                    div.skewWrapper {
                        padding: 0;
                        width: 100%;
                        height: 238px;
                    }
                `}
            >
              <SearchCardWrapper buttonUpperRight={buttonUpperRight}>
                <div className="container">
                  <div>
                    <div className="imageContainer">
                      <SkewImage
                        skew={pageWidth < 1000 ? '' : '18.88deg'}
                        containerStyling={`
                                    
                                    `}
                        height={196}
                      >
                        {image && (
                          <span>
                            <ImageBuilder
                              image={image}
                              alt={image.title}
                              width={370}
                              height={196}
                              decorativeOnly={true}
                            />
                          </span>
                        )}
                      </SkewImage>
                    </div>
                    <div className="textContainer">
                      <TypographyElement
                        variant="searchCardTitle"
                        component="p"
                        className="titleText"
                      >
                        {title}
                      </TypographyElement>
                      {text && (
                        <TypographyElement variant="p">
                          {text}
                        </TypographyElement>
                      )}
                    </div>
                  </div>
                </div>
                {href && (
                  <div className="buttonContainer">
                    <SkewContainer
                      containerStyling={`
                                height: 76px;
                                width: 90px;
                                max-height: 76px;
                                padding: 0;

                                div.skewWrapper {
                                    padding: 0 !important;
                                    height: 76px;
                                    max-height: 76px;
                                    width: 90px;
                                }

                                .svgContainer {
                                    position: relative;
                                    right: -20px;
                                    top: 30px;
                                    path {
                                        stroke: #000 !important;
                                    }
                                }

                                a {
                                    margin: 0 !important;
                                    background: none!important;
                                }
                            `}
                      style={`
                                background-color: #F9F9F9;                       
                            `}
                      skew={pageWidth < 1000 ? '' : '-18.88deg'}
                    >
                      <ButtonElement variant="arrow" noLink />
                    </SkewContainer>
                  </div>
                )}
              </SearchCardWrapper>

              <ColorBarHolder>
                <SkewImage
                  skew={pageWidth < 1000 ? '' : '18.88deg'}
                  height={30}
                >
                  <span>
                    <ColorBar
                      customStyling={`width: 120%; margin-left: -20px; position: relative !important`}
                    />
                  </span>
                </SkewImage>
              </ColorBarHolder>
            </SkewContainer>
          </SkewedWrapper>
        )}
      </LinkWrapper>
    </AccessabilityWrapper>
  )
}

export default SearchCard
